import { BloomupToken, getDecodedToken, Token } from "./api/AuthApi";
import React from "react";
import { Redirect } from "react-router-dom";
import Audio from "./img/audio.png";
import Exercise from "./img/exercise.png";
import Picture from "./img/picture.png";
import Text from "./img/text.png";
import Video from "./img/video.png";
import Question from "./img/question.png";
import { Quarter } from "./QuarterlyReport/Quarter";
import { DateTime } from "luxon";

export enum LessonType {
  TEXT = "text",
  VIDEO = "video",
  EXERCISE = "exercise",
  QUESTION = "question",
  AUDIO = "audio",
  PICTURE = "picture",
}

export enum DocumentType {
  MAILTEMPLATES = "mail-templates",
  BEST_PRACTICES = "best-practices",
  DRAAIBOEK = "draaiboek",
  BROCHURES = "brochures",
  VISUALS_AND_GRAPHICS = "visuals-and-graphics",
  VARIA = "varia",
  REPORT_Q1 = "Report Q1",
  REPORT_Q2 = "Report Q2",
  REPORT_Q3 = "Report Q3",
  REPORT_Q4 = "Report Q4",
}

export function RedirectIfNotAdmin() {
  const token = getDecodedToken();

  if (!token.admin) {
    return <Redirect push to={"/"} />;
  }

  return null;
}

export function RedirectIfNotLoggedIn() {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Redirect push to={"/"} />;
  }

  return null;
}

export function tokenIsExpired(token: Token | BloomupToken) {
  if (Date.now() >= token.exp * 1000) {
    localStorage.removeItem("token");
    return true;
  }

  return false;
}

export function typeStringToLessonType(type: string) {
  switch (type) {
    case LessonType.TEXT:
      return LessonType.TEXT;
    case LessonType.AUDIO:
      return LessonType.AUDIO;
    case LessonType.EXERCISE:
      return LessonType.EXERCISE;
    case LessonType.QUESTION:
      return LessonType.QUESTION;
    case LessonType.VIDEO:
      return LessonType.VIDEO;
    case LessonType.PICTURE:
      return LessonType.PICTURE;
    default:
      throw new Error("Lesson type is not valid.");
  }
}

export function lessonTypeToImage(type: LessonType) {
  switch (type) {
    case LessonType.TEXT:
      return Text;
    case LessonType.AUDIO:
      return Audio;
    case LessonType.EXERCISE:
      return Exercise;
    case LessonType.QUESTION:
      return Question;
    case LessonType.VIDEO:
      return Video;
    case LessonType.PICTURE:
      return Picture;
    default:
      throw new Error("Lesson type is not valid.");
  }
}

export function isB2C() {
  const type = process.env.REACT_APP_FRONTEND_TYPE;

  return type === "b2c";
}

export function quarterAndYearToFilterQueryString(
  quarter: Quarter,
  year: number
): string {
  const startMonth = (quarter - 1) * 3 + 1; // month is 0-based in Date, so subtract 1
  const start = DateTime.fromObject(
    {
      year: year,
      month: startMonth,
      day: 1,
      hour: 0,
      minute: 0,
    },
    { zone: "UTC" }
  );
  const rawEnd = DateTime.fromObject(
    {
      year: year,
      month: startMonth + 2,
      day: 2,
      hour: 23,
      minute: 59,
    },
    { zone: "UTC" }
  );
  const end = DateTime.fromObject(
    {
      year: year,
      month: startMonth + 2,
      day: rawEnd.endOf("month").day,
      hour: 23,
      minute: 59,
    },
    { zone: "UTC" }
  );

  return new URLSearchParams({
    start: start.toUTC().toISO() || "",
    end: end.toUTC().toISO() || "",
  }).toString();
}

export function formatNumber(number: number): string {
  if (Number.isInteger(number)) {
    return number.toString(); // Return the number as a string without decimal places
  } else {
    return number.toFixed(1); // Round the number to 1 decimal place using toFixed
  }
}

export function getCorrectUrl(url: string) {
  if (!url) {
    return url;
  }

  if (url.startsWith("http")) {
    return url;
  }

  return process.env.REACT_APP_IMAGE_BASE_URL + url;
}
