/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation joinOrgByEmailOrCodeHome($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n": types.JoinOrgByEmailOrCodeHomeDocument,
    "\n  mutation Login($input: LoginV2Input!) {\n    loginV2(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n      ... on LoginV2FailureResponse {\n        reason\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation ResetPassword($email: String!, $fromMindlab: Boolean) {\n    resetPassword(email: $email, fromMindlab: $fromMindlab)\n  }\n": types.ResetPasswordDocument,
    "\n  mutation choosePassword($input: ChoosePasswordV2Input!) {\n    choosePasswordV2(input: $input)\n  }\n": types.ChoosePasswordDocument,
    "\n  mutation Request2FASecrets($password: String!) {\n    request2FASecrets(password: $password) {\n      qrCode\n      secret\n    }\n  }\n": types.Request2FaSecretsDocument,
    "\n  mutation Enable2FA($input: Enable2FAInput!) {\n    enable2FA(input: $input)\n  }\n": types.Enable2FaDocument,
    "\n  mutation joinOrgByEmailOrCodeProfile($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n": types.JoinOrgByEmailOrCodeProfileDocument,
    "\n  mutation updateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      firstName\n      lastName\n      mindlabNewsletter\n      preferredLanguage\n    }\n  }\n": types.UpdateCurrentUserDocument,
    "\n  mutation RegisterHuman($input: RegisterHumanInput!) {\n    registerHumanV2(input: $input) {\n      accessToken\n      refreshToken\n      mindlabToken\n    }\n  }\n": types.RegisterHumanDocument,
    "\n  query getSSOUrlQuery($mindlabBusinessId: String!) {\n    getSSOUrl(mindlabBusinessId: $mindlabBusinessId)\n  }\n": types.GetSsoUrlQueryDocument,
    "\n  mutation loginWithSSO($input: LoginWithSSOInput!) {\n    loginWithSSO(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n    }\n  }\n": types.LoginWithSsoDocument,
    "\n  mutation RefreshAccessToken($refreshToken: String!) {\n    refreshAccessTokenV2(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n": types.RefreshAccessTokenDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation joinOrgByEmailOrCodeHome($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n"): (typeof documents)["\n  mutation joinOrgByEmailOrCodeHome($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Login($input: LoginV2Input!) {\n    loginV2(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n      ... on LoginV2FailureResponse {\n        reason\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: LoginV2Input!) {\n    loginV2(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n      ... on LoginV2FailureResponse {\n        reason\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPassword($email: String!, $fromMindlab: Boolean) {\n    resetPassword(email: $email, fromMindlab: $fromMindlab)\n  }\n"): (typeof documents)["\n  mutation ResetPassword($email: String!, $fromMindlab: Boolean) {\n    resetPassword(email: $email, fromMindlab: $fromMindlab)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation choosePassword($input: ChoosePasswordV2Input!) {\n    choosePasswordV2(input: $input)\n  }\n"): (typeof documents)["\n  mutation choosePassword($input: ChoosePasswordV2Input!) {\n    choosePasswordV2(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Request2FASecrets($password: String!) {\n    request2FASecrets(password: $password) {\n      qrCode\n      secret\n    }\n  }\n"): (typeof documents)["\n  mutation Request2FASecrets($password: String!) {\n    request2FASecrets(password: $password) {\n      qrCode\n      secret\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Enable2FA($input: Enable2FAInput!) {\n    enable2FA(input: $input)\n  }\n"): (typeof documents)["\n  mutation Enable2FA($input: Enable2FAInput!) {\n    enable2FA(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation joinOrgByEmailOrCodeProfile($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n"): (typeof documents)["\n  mutation joinOrgByEmailOrCodeProfile($emailOrCode: String!) {\n    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      firstName\n      lastName\n      mindlabNewsletter\n      preferredLanguage\n    }\n  }\n"): (typeof documents)["\n  mutation updateCurrentUser($input: UpdateCurrentUserInput!) {\n    updateCurrentUser(input: $input) {\n      firstName\n      lastName\n      mindlabNewsletter\n      preferredLanguage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterHuman($input: RegisterHumanInput!) {\n    registerHumanV2(input: $input) {\n      accessToken\n      refreshToken\n      mindlabToken\n    }\n  }\n"): (typeof documents)["\n  mutation RegisterHuman($input: RegisterHumanInput!) {\n    registerHumanV2(input: $input) {\n      accessToken\n      refreshToken\n      mindlabToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSSOUrlQuery($mindlabBusinessId: String!) {\n    getSSOUrl(mindlabBusinessId: $mindlabBusinessId)\n  }\n"): (typeof documents)["\n  query getSSOUrlQuery($mindlabBusinessId: String!) {\n    getSSOUrl(mindlabBusinessId: $mindlabBusinessId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation loginWithSSO($input: LoginWithSSOInput!) {\n    loginWithSSO(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation loginWithSSO($input: LoginWithSSOInput!) {\n    loginWithSSO(input: $input) {\n      ... on TokenResponse {\n        mindlabToken\n        accessToken\n        refreshToken\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RefreshAccessToken($refreshToken: String!) {\n    refreshAccessTokenV2(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshAccessToken($refreshToken: String!) {\n    refreshAccessTokenV2(refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;