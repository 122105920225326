import React, { useState } from "react";
import {
  BusinessStatistics,
  ConnectedBusiness,
  getConnectedBusinesses,
  TestStatistics,
} from "../api/BusinessApi";
import "./BusinessDashboard.css";
import { StatsBox } from "./StatsBox";
import UsersIcon from "../img/usersIcon.svg";
import ModulesIcon from "../img/modulesIcon.svg";
import TestIcon from "../img/testenIcon.svg";
import FaceScanIcon from "../img/faceScansIcon.svg";
import TevredenIcon from "../img/tevredenIcon.png";
import SkillsIcon from "../img/skillsIcon.png";
import PodcastIcon from "../img/podcastsIcon.svg";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { formatNumber } from "../Utils";

export function StatsBoxRow({
  stats,
  className = "",
  premium = false,
}: {
  stats: BusinessStatistics;
  className?: string;
  premium?: boolean;
}) {
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);
  const [hasPremium, setHasPremium] = useState(premium);

  const { t } = useTranslation("hr-dashboard");
  const foundBusiness = connectedBusinesses.find(
    (b) => b.business.id === selectedBusiness
  );
  useEffectOnce(() => {
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);

      setHasPremium(result.filter((b) => b.business.type >= 1).length >= 1);
    });
  });

  const getTotalTests = (tests: TestStatistics[]): number => {
    return tests.reduce((sum, test) => sum + test.total, 0);
  };

  return (
    <div className={`mb-4 wrap gap flex stats-box-row ${className}`}>
      <StatsBox
        title={t("businessStats.users")}
        icon={UsersIcon}
        className={"users"}
        number={formatNumber(stats.numberOfUsers)}
      />
      <StatsBox
        title={t("businessStats.modules")}
        icon={ModulesIcon}
        className={"modules"}
        number={formatNumber(stats.totalModulesStarted)}
      />
      <StatsBox
        title={t("businessStats.tests")}
        icon={TestIcon}
        className={"testen"}
        number={formatNumber(getTotalTests(stats.tests))}
      />
      <StatsBox
        title={t("businessStats.faceScans")}
        icon={FaceScanIcon}
        className={"face-scans"}
        number={formatNumber(stats.faceScans)}
      />
      <StatsBox
        title={t("businessStats.tevredenSc")}
        icon={TevredenIcon}
        className={"tevreden"}
        number={formatNumber(stats.evaluationScore)}
      />
      <StatsBox
        title={t("businessStats.skills")}
        icon={SkillsIcon}
        className={"skills"}
        premium={true}
        locked={!hasPremium}
        number={hasPremium ? formatNumber(stats.totalSkillsStarted) : ""}
      />
      <StatsBox
        title={t("businessStats.podcasts")}
        icon={PodcastIcon}
        className={"podcasts"}
        premium={true}
        locked={!hasPremium}
        number={
          hasPremium
            ? formatNumber(
                stats.podcasts
                  .map((podcast) => podcast.total)
                  .reduce((sum, total) => sum + total, 0)
              )
            : ""
        }
      />
    </div>
  );
}
