import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { graphql } from "./__generated__";
import { RotatingLines } from "react-loader-spinner";
import { t } from "i18next";
import { useEffect } from "react";

const loginWithSSOMutation = graphql(`
  mutation loginWithSSO($input: LoginWithSSOInput!) {
    loginWithSSO(input: $input) {
      ... on TokenResponse {
        mindlabToken
        accessToken
        refreshToken
      }
    }
  }
`);

export function SSORedirectV2({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const code = queryParams.get("code");
  const params = useParams<{ businessId: string }>();

  const [loginWithSSO, { loading, error }] = useMutation(loginWithSSOMutation, {
    async onCompleted(data) {
      if (
        data &&
        data.loginWithSSO.__typename === "TokenResponse" &&
        data.loginWithSSO.mindlabToken &&
        data.loginWithSSO.accessToken &&
        data.loginWithSSO.refreshToken
      ) {
        localStorage.setItem(
          "bloomupAccessToken",
          data.loginWithSSO.accessToken
        );
        localStorage.setItem(
          "bloomupRefreshToken",
          data.loginWithSSO.refreshToken
        );
        localStorage.setItem("token", data.loginWithSSO.mindlabToken);
        setAuthenticated(true);
        history.replace("/home");
      }
    },
  });

  //The useEffect is a workaround to have the function run async at render
  useEffect(() => {
    if (code) {
      const loginFunc = async () => {
        await loginWithSSO({
          variables: {
            input: {
              mindlabBusinessId: params.businessId,
              authorizationCode: code,
            },
          },
        });
      };
      loginFunc();
    }
  }, [code, loginWithSSO, params.businessId]);

  return (
    <div className="container">
      {loading && (
        <RotatingLines
          strokeColor="#224453"
          strokeWidth="5"
          animationDuration="0.75"
          width={"115"}
          visible={loading}
        />
      )}
      {error && <div>{t("login:genericError")}</div>}
    </div>
  );
}
